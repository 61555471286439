<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos - Interesados</h4>
            <div class="small text-muted">Administración de contactos interesados sobre el producto</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Interesado">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

          </b-col>                    
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col lg="4" sm="4">               
            <b>Vendedor</b>
            <div v-if="this.product">                        
              <b-avatar :src="product.customer.image"
                        v-if="product.customer.image">
              </b-avatar>
              <b-avatar v-else 
                        icon="building"                                            
                        variant="dark">
              </b-avatar>     
              &nbsp;<b>{{product.customer.name}}</b>   
              <b-icon icon="circle-fill" v-if="!product.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="INACTIVO" />               
            </div>
          </b-col>

          <b-col lg="8" sm="8">
            <b>Producto</b>
            <div v-if="this.product">                        
              <b-avatar :src="product.image[0]"
                        v-if="product.image[0]">
              </b-avatar>
              <b-avatar v-else 
                        icon="box-seam"                                            
                        variant="dark">
              </b-avatar>     
              &nbsp;<b>{{product.name}}</b>   
              <b-icon icon="circle-fill" v-if="!product.active" class="ml-2" variant="danger" v-b-tooltip.hover title="INACTIVO" />               

              <span v-if="product.amount" class="ml-3 crud-contact-interested-price"> 
                <b-badge variant="dark">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(product.amount.amount_total)}}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="dark">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency:'ARS'}).format(0)}}
                </b-badge>
              </span>
            </div>

          </b-col>  
        </b-row>
      </b-card>
      <b-card>
        <b-row>      
          <b-col lg="12">
            <b-table class="mb-0 crud-contact-interested-table"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"                  
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">                       

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template> 

                <template v-slot:cell(date)="data">
                  {{moment(data.item.date).format('DD MMMM YYYY')}}
                </template>

                <template v-slot:cell(buyer)="data">
                  <div v-if="data.item.customer_buyer">                        
                    <b-avatar :src="data.item.customer_buyer.image"
                              v-if="data.item.customer_buyer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>     
                    &nbsp;<b>{{data.item.customer_buyer.name}}</b>   
                    <b-icon icon="circle-fill" v-if="!data.item.customer_buyer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="INACTIVO" />               
                  </div>
                </template>    

                <template v-slot:cell(message)="data">                  
                  <p :id="'message_' + data.item.id" 
                      v-html="data.item.message" 
                      @click="copyMessage(data.item)" 
                      class="copy-message"
                      v-b-tooltip.hover
                      title="Click para copiar texto"></p>
                </template>                                           

                <template v-slot:cell(send)="data">           
                  <div v-if="data.item.message_send">       
                    {{moment(data.item.message_send).format('DD MMMM YYYY')}}
                  </div>
                  <div v-else>       
                    Sin Aviso
                  </div>
                </template>       

                <template v-slot:cell(sold)="data">                  
                  <div v-if="data.item.sold">                        
                    <b-icon icon="check2-circle" variant="success" scale="2" title="Vendido"></b-icon>
                  </div>
                </template>       

                <template v-slot:cell(active)="data">
                  <div v-if="data.item.active">
                    <b-badge variant="success">Activo</b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Inactivo</b-badge>
                  </div>
                </template>
                
                <template v-slot:cell(commissions)="data">     
                  <div v-if="data.item.commissions_total>0">
                    {{Intl.NumberFormat('es-AR',{style:'currency', currency: "ARS"}).format(data.item.commissions_total)}}
                  </div>
                </template>

                <template v-slot:cell(payment)="data">                  
                  <div v-if="data.item.payment">                        
                    <b-icon icon="check2-circle" variant="success" scale="2" title="Cobrado"></b-icon>
                  </div>
                </template>

                <template v-slot:cell(origin)="data">
                  <div v-if="data.item.origin == 'website'">
                    <span class="text-success font-weight-bold">Sitio Web</span>
                  </div>
                  <div v-if="data.item.origin == 'system'">
                    <span class="text-info font-weight-bold">Sistema</span>
                  </div>                  
                  <div v-if="data.item.origin != 'system' && data.item.origin != 'website'">
                    <span class="text-secondary font-weight-bold">Sin Clasificar</span>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="messageSend(data.item)" v-if="!data.item.sold">
                      <i class="fa fa-send crud-product-marketplace-action-icon ml-0 mr-0"></i> Enviar Mensaje
                    </b-dropdown-item>                                        

                    <b-dropdown-item @click="openSold(data.item)" v-if="!data.item.sold">
                      <i class="fa fa-check-circle-o crud-product-marketplace-action-icon ml-0 mr-0"></i> Vendido
                    </b-dropdown-item>                    
                    
                    <b-dropdown-item @click="checkPayment(data.item)" v-if="data.item.sold && !data.item.payment">
                      <i class="fa fa-dollar crud-product-marketplace-action-icon ml-0 mr-0"></i> Cobrado
                    </b-dropdown-item>   

                    <b-dropdown-item @click="uncheckPayment(data.item)" v-if="data.item.payment">
                      <i class="fa fa-dollar crud-product-marketplace-action-icon ml-0 mr-0"></i> No Cobrado
                    </b-dropdown-item>  

                    <b-dropdown-item @click="checkActive(data.item)" v-if="!data.item.active">
                      <i class="fa fa-check crud-product-marketplace-action-icon ml-0 mr-0"></i> Activar
                    </b-dropdown-item>   

                    <b-dropdown-item @click="uncheckActive(data.item)" v-if="data.item.active">
                      <i class="fa fa-close crud-product-marketplace-action-icon ml-0 mr-0"></i> Inactivar
                    </b-dropdown-item>   

                    <b-dropdown-header>Acciones</b-dropdown-header>                         
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.customers_buyer_id"
                        :where="conditionCustomersActive"/>                                        
          </b-col>
          <b-col md="12">
            <b-form-group label="Mensaje">
              <b-form-textarea                
                v-model="crud.form.message"
                placeholder="Ingrese un mensaje para el vendedor..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>                                                    
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- VENDIDO -->
      <b-modal v-model="modal.sold.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.sold.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Procentaje de Comisión">
              <b-input-group class="w-100 mb-1">
                <b-form-input type="number"
                              v-model="crud.sold.commissions"
                              step="0.01"
                              placeholder="Comisión aplicada a la venta de este producto">
                </b-form-input>
                <b-input-group-append>
                  <div class="input-group-text">
                    <b-icon class="mb-0" icon="percent"></b-icon>
                  </div>
                </b-input-group-append>                 
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Importe Producto">
              <b-input-group class="w-100 mb-1">
                <b-input-group-append>
                  <div class="input-group-text">
                    <i class="fa fa-dollar mb-0"></i>                    
                  </div>
                </b-input-group-append>                 
                <b-form-input type="number"
                              step="0.01" 
                              v-model="crud.sold.products_total"
                              placeholder="Importe del producto">
                </b-form-input>                                
              </b-input-group>        
            </b-form-group>                  
          </b-col>            
          <b-col md="12">
            <b-form-group label="Importe de Comsión">
              <b-input-group class="w-100 mb-1">
                <b-input-group-append>
                  <div class="input-group-text">
                    <i class="fa fa-dollar mb-0"></i>                    
                  </div>
                </b-input-group-append>                 
                <b-form-input type="number" 
                              step="0.01"
                              v-model="soldCommissionsCalc"
                              readonly
                              placeholder="Importe de comisión a cobrar">
                </b-form-input>   
              </b-input-group>              
            </b-form-group>      
          </b-col>           
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.sold.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="checkSold()">Guardar</b-button>          
        </div>
      </b-modal>    

      <!-- MENSAJE ENVIADO -->
      <b-modal v-model="modal.message.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.message.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Envío de Mensaje">
              <b-form-datepicker size="sm" v-model="crud.message.message_send" placeholder="Envío de Mensaje" local="es"></b-form-datepicker>                      
            </b-form-group>               
          </b-col>       
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.message.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveMessageSend()">Guardar</b-button>          
        </div>
      </b-modal>            
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import Param from '@/config/parameters'
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 

  export default {
    components: {
      Imagen,   
      FindObject,   
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudContactInterested',
          elements: {}
        },       
        parameters: {          
          haveMarketplace: Helper.hasParametersAccess(Param.P18),          
        },        
        productID: null,
        product: null,              
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class: 'align-middle', width:"60px"},
            {key: 'date', label: 'Fecha', class: 'align-middle', width:"100px"},
            {key: 'buyer', label: 'Interesado', class: 'align-middle', width:"150px"},            
            {key: 'message', label: 'Mensaje', class: 'align-middle', width:"360px"},
            {key: 'active', label: 'Activo', class: 'align-middle text-center', width:"70px"},
            {key: 'send', label: 'Enviado', class: 'align-middle text-center', width:"70px"},
            {key: 'sold', label: 'Vendido', class: 'align-middle text-center', width:"70px"},
            {key: 'commissions', label: 'A Cobrar', class: 'align-middle text-center', width:"90px"},
            {key: 'payment', label: 'Cobrado', class: 'align-middle text-center', width:"70px"},            
            {key: 'origin', label: 'Origen', class: 'align-middle text-center', width:"50px"}, 
            {key: 'f_action', label:'', class: 'align-middle', width:"50px"},
          ],
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            customer_seller_id: 0,
            customer_buyer_id: 0,
            customer_buyer: null,
            product_id: 0,   
            message: ''         
          },      
          sold: {
            id: 0,
            product_id: 0,
            commissions: 0,
            products_total: 0,
            commissions_total: 0,            
          },
          message: {
            id: 0,
            message_send: ''
          }    
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          sold: {
            active: false,
            title: ''
          },         
          message: {
            active: false,
            title: ''
          },                    
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    computed: {
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },

      getNextInterested() {
        var text = ''
        if(this.table.items) {          
          switch (this.table.items.length) {
            case 0:
              text = "PRIMER"
              break;

            case 1:
              text = "SEGUNDO"
              break;         

            case 2:
              text = "TERCER"
              break;  

            case 3:
              text = "CUARTO"
              break;  
              
            case 4:
              text = "QUINTO"
              break;  
              
            case 5:
              text = "SEXTO"
              break;                
              
            default:
              text = "OTRO"
              break;
          }
        
        }
        return text
      },

      soldCommissionsCalc () {
        if(this.crud.sold.commissions && this.crud.sold.products_total) {
          return parseFloat((this.crud.sold.products_total * this.crud.sold.commissions) / 100)
        }
      }     
    },
    mounted() {
      this.productID = this.$route.params.productID  

      if(!this.parameters.haveMarketplace) {
        this.$router.push({ name: 'Error403' })
      }

      this.loadByFilter()
      this.showByProduct()
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      add() {
        this.crud.form.id = 0        
        this.crud.form.customer_seller_id = this.product.customer.id
        this.crud.form.customer_buyer_id = 0,
        this.crud.form.product_id = this.productID

        this.modal.form.title = "Nuevo Interesado"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        var name = item.customer_buyer.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el interesado (' + this.crud.form.id + ') - '+ name + '?', {
          title: 'Borrar Interesado',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProductMarketplace(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.loadByFilter()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var result = serviceAPI.agregarProductMarketplace(this.crud.form);
        
        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadByFilter() {
        var result = serviceAPI.filterProductMarketplace({
          product_id: this.productID,
        })

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
      showByProduct() {
        var result = serviceAPI.mostrarProducto(this.productID)

        result.then((response) => {
          var data = response.data
          this.product = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });              
      },

      loadCustomers (object) {        
        if(object){
          this.crud.form.customer_buyer = object
          this.crud.form.customer_buyer_id = object.id        
        
          var message = ""
          message = message = "👋🏻 Hola " + this.product.customer.name + "\n" 
          message = message + "👉🏻 " + this.getNextInterested + " INTERESADO\n"
          message = message + "👉🏻 PARA EL PRODUCTO: " + this.product.code + " " + this.product.name + "\n\n"
          message = message + "👉🏻 NOMBRE: "+ this.crud.form.customer_buyer.name + "\n"  
          message = message + "👉🏻 WSAPP: " + this.crud.form.customer_buyer.phone + "\n\n"
          message = message + "Contactate para coordinar la venta y luego nos avisas!\n"
          message = message + "Muchas gracias por confiar en nosotros"

          this.crud.form.message = message          
        } else {
          this.crud.form.customer_buyer = null
          this.crud.form.customer_buyer_id = 0
        }
      },     
      copyMessage(data) {   
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = data.message;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.$awn.success("Mensaje copiado en el portapapeles");        
      }, 
      
      openSold(item) {        
        if(!this.product.amount) {
          this.$awn.alert("Producto sin precio");
          return false
        }

        this.crud.sold.id = item.id        
        this.crud.sold.product_id = this.productID
        this.crud.sold.commissions = parseFloat(item.customer_buyer.commissions)
        this.crud.sold.products_total = parseFloat(this.product.amount.amount_total) 
                
        this.modal.sold.title = "Marcar como vendido"
        this.modal.sold.active = true
      },
      checkSold() {        
        let loader = this.$loading.show();

        this.crud.sold.commissions_total = this.soldCommissionsCalc
        
        var result = serviceAPI.checkSoldProductMarketplace(this.crud.sold);
        
        result.then((response) => {
          this.modal.sold.active = false
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      checkPayment(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.checkPaymentProductMarketplace({
          id: item.id          
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      uncheckPayment(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.uncheckPaymentProductMarketplace({
          id: item.id
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },

      checkActive(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.checkActiveProductMarketplace({
          id: item.id          
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      uncheckActive(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.uncheckActiveProductMarketplace({
          id: item.id
        });
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },

      messageSend(item) {
        this.crud.message.id = item.id        
        this.crud.message.message_send = moment().format('YYYY-MM-DD')
        
        this.modal.message.title = "Mensaje Enviado"
        this.modal.message.active = true        
      },
      saveMessageSend() {
        let loader = this.$loading.show();

        var result = serviceAPI.messageSendProductMarketplace(this.crud.message);
        
        result.then((response) => {          
          loader.hide()
          this.loadByFilter()
          this.modal.message.active = false
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.modal.message.active = false
          this.$awn.alert(Error.showError(error));
        })  
      }
    }    
  }
</script>
<style>
  .products-color-table-show {
    font-size: 25px;
  }
  .crud-contact-interested-price span {
    font-size: 13px;
  }
  .copy-message {
    cursor: pointer;
  }
  .crud-product-marketplace-action-icon {
    color: #000 !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    font-size: 18px;  
  }
  .crud-contact-interested-table {
    min-height: 180px;
  }
</style>